import React from 'react';
import { UeContainer } from '@oneaudi/falcon-tools';
import { ThemeProvider } from '@oneaudi/unified-web-common';
import { SecondaryLinkCard } from '../SecondaryLinkCard/index';
import { GroupContainer } from './styles';
import { CardsDataProps, CardsContentArr } from '../../types/shared';

export const SecondaryLinksContent: React.FC<CardsDataProps> = ({ cardsData }) => {
  let cardsArray: CardsContentArr = [...cardsData.cards];

  if (cardsArray.length > 9) {
    const slicedArray = cardsArray.slice(0, 9);
    cardsArray = slicedArray;
  }

  return (
    <UeContainer propertyPath="cards" label="Cards">
      <ThemeProvider>
        <GroupContainer>
          {cardsArray.map((card, index: number) => (
            <SecondaryLinkCard
              key={Math.random()}
              cardData={{
                showIcon: cardsData.displayIcons,
                openLinkNewTab: card.openLinkNewTab,
                URL: card.URL,
                cardTitle: card.cardTitle,
                iconName: card.iconName,
              }}
              index={index}
            />
          ))}
        </GroupContainer>
      </ThemeProvider>
    </UeContainer>
  );
};

import get from 'lodash.get';
import { ThemeProps } from '../../types/shared';
import { SpacingVars } from '../../types/theme';

export const getResponsiveSpacing =
  /* eslint-disable @typescript-eslint/no-redundant-type-constituents */

  (size: keyof SpacingVars | string = '') =>
    (styledComponentProps: ThemeProps): string => {
      const spacing = get(styledComponentProps, `theme.responsive.spacing.${size.toLowerCase()}`);
      if (spacing) return `var(${spacing as string})`;
      return '';
    };

/* eslint-disable @typescript-eslint/no-explicit-any */
import { BreakpointStyles, responsiveStyles } from '../../types/responsiveStyles';

const BREAKPOINTS = {
  xs: 0,
  s: 375,
  m: 768,
  l: 1024,
  xl: 1440,
  xxl: 1920,
};

export const createMediaQuery = (styles: BreakpointStyles<any>) => (): string => {
  return responsiveStyles(styles, BREAKPOINTS);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import { BreakpointLabel, Breakpoints } from './theme';

type BreakpointStylesMappingFunction<T> = (bp: BreakpointLabel) => T;
export type BreakpointStyles<T> = {
  [style: string]: Partial<Breakpoints<T> | BreakpointStylesMappingFunction<T>>;
};

const isScalar = (unknown: any): unknown is string | number => typeof unknown !== 'object';

const BREAKPOINT_ORDER: BreakpointLabel[] = ['xs', 's', 'm', 'l', 'xl', 'xxl'];

// reduce 'breakpoints' variable to only contain breakpoints with unique values
export const collapseBreakpoints = <T>(
  breakpoints: Partial<Breakpoints<T>>,
): Partial<Breakpoints<T>> | T => {
  const [firstBreakpointLabel, ...restBreakpointsLabels] = BREAKPOINT_ORDER.filter((breakpoint) =>
    Boolean(breakpoints[breakpoint]),
  );
  const firstValue = breakpoints[firstBreakpointLabel];

  const collapsedBreakpoints = {
    [firstBreakpointLabel]: firstValue,
  } as Partial<Breakpoints<T>>;
  let previousValue = firstValue;

  for (const currentBreakpointLabel of restBreakpointsLabels) {
    const currentValue = breakpoints[currentBreakpointLabel];
    if (currentValue !== previousValue) {
      collapsedBreakpoints[currentBreakpointLabel] = currentValue;
      previousValue = currentValue;
    }
  }

  // if only the 'xs' breakpoint is left return it directly
  return isEqual(Object.keys(collapsedBreakpoints), ['xs'])
    ? collapsedBreakpoints?.xs || collapsedBreakpoints
    : collapsedBreakpoints;
};

const mapStyleValueForBreakpoint = (styleValue: any, breakpointLabel: BreakpointLabel) => {
  switch (typeof styleValue) {
    case 'function':
      return styleValue(breakpointLabel);
    case 'object':
      return styleValue[breakpointLabel];
    default:
      return styleValue;
  }
};

export const responsiveStyles: any = <T extends Record<string, Partial<Breakpoints<T>>>>(
  styles: BreakpointStyles<T>,
  breakpointLabels: Breakpoints<number>,
) => {
  const breakpointKeys = Object.keys(breakpointLabels) as BreakpointLabel[];

  const unoptimizedBreakpointStyles = mapValues(styles, (styleValue: any) => {
    const breakpoints: Breakpoints<T> = {} as Breakpoints<T>;
    for (const breakpointLabel of breakpointKeys) {
      breakpoints[breakpointLabel] = mapStyleValueForBreakpoint(styleValue, breakpointLabel);
    }
    return breakpoints;
  });

  const collapsedBreakpointStyles = mapValues(unoptimizedBreakpointStyles, collapseBreakpoints);

  const simpleStyles: Record<string, string | number> = {};
  const breakpointStyles: Record<string, Partial<Breakpoints<T>>> = {};

  for (const [key, value] of Object.entries(collapsedBreakpointStyles)) {
    if (isScalar(value)) {
      simpleStyles[key] = value;
    } else {
      breakpointStyles[key] = value as any;
    }
  }

  const simpleStyleStrings = Object.entries(simpleStyles).map(
    ([styleRule, styleValue]) => `${styleRule}:${styleValue};`,
  );

  const breakpointStyleStrings = breakpointKeys
    .filter((breakpointLabel) =>
      Object.values(breakpointStyles).some((breakpoints) => !!breakpoints[breakpointLabel]),
    )
    .map((breakpointLabel) => {
      const minWidth = breakpointLabels[breakpointLabel];
      const serializedStyles = Object.entries(breakpointStyles)
        .filter(([, breakpoints]) => Boolean(breakpoints[breakpointLabel]))
        .map(([styleRule, breakpoints]) => {
          if (typeof breakpoints?.[breakpointLabel] !== 'object') {
            return `${styleRule}:${breakpoints?.[breakpointLabel]};`;
          }
          return `${styleRule}: ${JSON.stringify(breakpoints?.[breakpointLabel])};`;
        })
        .join('');
      return `@media (min-width:${minWidth}px) {${serializedStyles}}`;
    });

  return [...simpleStyleStrings, ...breakpointStyleStrings].join('');
};

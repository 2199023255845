/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import { ContentServiceV1 } from '@oneaudi/content-service';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { GfaServiceConfigProviderV1 } from '@volkswagen-onehub/gfa-service-config-provider';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import App from './FeatureApp';
import { ContentContextProvider } from './context/ContentContext';
import { TrackingServiceContextProvider } from './context/TrackingServiceContext';

export interface FeatureDependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 'gfa:service-config-provider': GfaServiceConfigProviderV1;
  readonly 'gfa:locale-service': GfaLocaleServiceV1;
  readonly 'audi-render-mode-service'?: RenderModeServiceV1;
  readonly 'audi-content-service'?: ContentServiceV1;
  readonly 'audi-tracking-service'?: TrackingServiceV2;
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureDependencies> = {
  dependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'gfa:locale-service': '^1.0.0',
      'audi-content-service': '^1.0.0',
    },
    externals: {
      react: '^16.13.1 || ^17.0.2 || ^18',
      'react-dom': '^16.13.1 || ^17.0.2 || ^18',
      'styled-components': '*',
    },
  },
  optionalDependencies: {
    featureServices: {
      'audi-render-mode-service': '^1.0.0',
      'audi-tracking-service': '^2.0.0',
    },
  },

  create: ({ featureServices }: FeatureAppEnvironment<FeatureDependencies, void>) => {
    const loggerService = featureServices['s2:logger'];
    const contentService = featureServices['audi-content-service'];
    const trackingService = featureServices['audi-tracking-service'] as TrackingServiceV2;
    if (trackingService) {
      trackingService.featureAppName = __FEATURE_APP_NAME__ || 'fa-nar-secondary-links';
    }
    loggerService?.info('Feature App created.');

    const configProvider = featureServices['gfa:service-config-provider'];

    return {
      render: () => {
        return (
          <UniversalEditorProvider
            contentService={featureServices['audi-content-service']!}
            renderModeService={featureServices['audi-render-mode-service']}
          >
            <ContentContextProvider contentService={contentService!} loggerService={loggerService}>
              <TrackingServiceContextProvider trackingService={trackingService}>
                <App configProvider={configProvider} />
              </TrackingServiceContextProvider>
            </ContentContextProvider>
          </UniversalEditorProvider>
        );
      },
    };
  },
};

export default featureAppDefinition;

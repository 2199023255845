import type { Logger } from '@feature-hub/logger';
import { createContext } from 'react';
import { CardsData } from '../../types/shared';

type ContentContextState = {
  content: CardsData;
  loggerService?: Logger;
};

export const ContentContext = createContext<ContentContextState>({
  content: {
    cards: [],
    displayIcons: false,
  },
});
